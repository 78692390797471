<template>
	<v-container fluid pa-0>
        <v-row class="px-12 mt-6">
            <v-tabs 
            class="my-tabs"
            v-model="tab"
            :vertical="!$vuetify.breakpoint.xsOnly"
            show-arrows
            >
                <v-tab v-for="item in tocItems" :key="item.title" :href="item.link">
                    {{ item.title }}
                </v-tab>
                <v-tabs-items :value="tab">
                    <v-tab-item transition="v-fade-transition" value="about-ssb">
                        <h2>About SSB</h2>
                        <p>No corporate gathering is complete without delicious food from a professional catering service. There is no better way to build bridges, develop networks, or impress your clients than over some delectable foods that Servers Standing By offers. If you are in need of some of the finest culinary services between San Francisco and the Silicon Valley, then the professional staff at Servers Standing By are the only people to which you need to turn.</p>
                        <p>We are a family operated Catering company located in San Mateo. With dedicated and experienced cooks, we are committed to delivering an affordable and top quality culinary experience for your gatherings. We understand the importance of a superior service when it comes to entertaining your professional associates. Our service is accessible and easy to use. With online ordering and billing, daily lunch delivery, personalized service and custom menu planning, we will tailor our services to your specific tastes and to your budget.</p>
                        <p>For a superior catering service you and your business will not forget, get in touch with us over at Servers Standing By. We treat your corporate gatherings with the same level of professionalism that your business deserves. Our cooks use only the best ingredients and food in their preparations. You, your clients and associates will taste the difference Servers Standing By makes. If you are located between San Francisco and Silicon Valley and you want to make a lasting impression at the office, then there is no better way to do so than to take advantage of Servers Standing By's exquisite catering.</p>
                    </v-tab-item>
                    <v-tab-item transition="v-fade-transition" value="why-us">
                        <h2>Why Us?</h2>
                        <p>We are caterers! We process your order, we prepare your food, AND we deliver your food, all under one business, one kitchen!
Our team has been delivering fresh, tasty meals to hundreds of thousands of people across the bay area for over a decade. We have over 100 menus that our account specialist can choose from to make your office program a huge success.
Lower cost, increase employee happiness. With our group ordering platform, your company's lunch program will be easier to manage than ever. With the hybrid work model employees can order food the day they are in the office. All without costly service charges similar to those of third-party catering order platforms!
                        </p>
                    </v-tab-item>
                    <v-tab-item transition="v-fade-transition" value="our-services">
                        <v-container fluid>
                            <v-row>
                                <v-col>
                                    <h2>SERVICES</h2>
                                    <ul>
                                        <li>Family Style Drop off Breakfast and Lunch Catering</li>
                                        <li>Individually Boxed Meal Drop off Breakfast and Lunch Catering</li>
                                        <li>Group Ordering</li>
                                    </ul>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <h3>Family Style</h3>
                                    <h4>How it works</h4>
                                    <p>Place orders with your account specialist, receive confirmation email and we will do the rest from clear labeling of each buffet item to timely delivery of well-prepared foods. We provide plates, napkins, and utensils.  All of our buffets are set up with disposable serving ware and serving trays.</p>
                                    <p>Chaffing service is available upon request. 20% service fee will be added to F&amp;B Total</p>
                                    <br/>
                                    <h3>Individually Boxed Group Ordering</h3>
                                    <h4>How it works</h4>
                                    <p>Set up a meal program with our onboarding account specialist.</p>
                                    <p>Every employee will be able to preselect meals from preset daily menus with more than enough variety thanks to our “one kitchen multiple concepts” system.</p>
                                    <p>Orders are confirmed with an account specialist, our team prepares, cooks and delivers all meals. The meals are clearly labeled and individual separated. All food is set up for employees to find their meals with ease using our specialized meal group ordering system.</p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item transition="v-fade-transition" value="order-info">
                        <h2>ORDERING INFORMATION</h2>
                        <p>Corporate Breakfast and Luncheon</p>
                        <p>Please email orders to orders@ssbcatering.com</p>
                        <p>A confirmation will be sent via email upon receipt of all orders</p>
                        <p>We do request that all cancellations be made more than 24 hours in advance.</p>
                        <p>50% of invoice total will be charged for all cancellations made without a 24 hour notice</p>
                    </v-tab-item>
                    <v-tab-item transition="v-fade-transition" value="delivery">
                        <h2>Delivery Minimums and Fees</h2>
                        <p><strong>Brisbane to Mountain View</strong></p>
                        <p>$400 Food and Beverage-$25 Delivery Fee</p>
                        <p><strong>San Francisco and Daly City</strong></p>
                        <p>$500.00 Food and Beverage-$35 Delivery Fee</p>
                        <p><strong>Sunnyvale-Milpitas-Santa Clara-North San Jose </strong></p>
                        <p>$700 Food and Beverage-$35 Delivery Fee</p>
                        <p><strong>San Leandro-Fremont</strong></p>
                        <p>$700 Food and Beverage-$35 Delivery Fee</p>
                        <p><strong>Oakland-Alameda-Emeryville</strong></p>
                        <p>$1500 Food and Beverage-$50 Delivery Fee</p>
                        <br/>
                        <p>***Please inquire regarding other delivery locations***</p>
                    </v-tab-item>
                    <v-tab-item transition="v-fade-transition" value="schedule">
                        <h2>DELIVERY SCHEDULE</h2>
                        <p>Monday through Friday</p>
                        <p>Breakfast Delivery:</p>
                        <p>6:00am to 9:30am</p>
                        <p>Lunch Delivery:</p>
                        <p> 10:00am to 2:00pm</p>
                        <p>Deliveries are made within a 30-minute window before your desired drop off time</p>
                    </v-tab-item>
                    <v-tab-item transition="v-fade-transition" value="pay-info">
                        <h2>Payment Information</h2>
                        <p>We accept:</p>
                        <p>Corporate Checks, ACH(for Recurring Clients), Credit Cards(Visa/MC/AMEX)</p>
                        <p>(All Credit Cards subject to 2.5% processing fee)</p>
                        <p>Evening and Weekend Deliveries are available upon special request (Please inquire)</p>
                        <br/>
                        <p><em>***Pricing and minimums may vary outside of normal business delivery times and locations***</em></p>
                        <p><em>***Chaffing Service Available, Please Inquire***</em></p>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-row>
	</v-container>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
	name: 'AboutUs',
    data() {
        return {
            tocItems: [
                { title: "About SSB", link: "#about-ssb"} ,
                { title: "Why Us?" , link: "#why-us" },
                { title: "Our Services", link: "#our-services" },
                { title: "Order Info", link: "#order-info" },
                { title: "Delivery Info", link: "#delivery" },
                { title: "Delivery Schedule", link: "#schedule" },
                { title: "Payment Info", link: "#pay-info" }
            ]
        }
    },
    computed: {
        tab: {
            set (tab) {
                this.$router.replace({ query: { ...this.$route.query, tab } })
            },
            get () {
                return this.$route.query.tab
            }
        }
    }

}

</script>


<style lang="scss" scoped>

.my-tabs [role="tab"] {
    justify-content: flex-start;
    text-align: left;
}

h2 {
	// font-size: 2em;
	line-height: 2em;
	font-weight: 300;
	margin: 0 0 0.5em 0;
	padding: 0;
	letter-spacing: 0.125em;
	text-transform: uppercase;
	opacity: 1;
}

h3 {
	// font-size: 1.4em;
	line-height: 1.25em;
	font-weight: 300;
	letter-spacing: 0.07em;
}

ul {
	display: inline-block;
    text-align: left;
}

// p, li {
// 	font-weight: 300;
// 	font-size: 0.8em;
// 	// letter-spacing: 0.08em;
// }
.summary {
	font-size: 1em;
	// letter-spacing: 0.07em;
	// font-weight: 300;
	// color: #bbb;
}

.mysubtitle p {
	// font-weight: 300;
	// font-size: 1.4em;
	letter-spacing: 0.07em;
	font-weight: 300;
	color: #bbb;
}

.darksection {
	background-color: #192024;
	position: relative;
	color: #c5c7c8;
	// font-size: 1em;
	font-weight: 300;
}

.greysection {
	background: #f8f8f8;
}


</style>